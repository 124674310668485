.page-background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}
