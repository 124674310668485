@use "./colors" as colors;

@font-face {
  font-family: "GoldLines";
  src: url("../../public/assets/fonts/GoldLinesTrial-Regular.woff2")
      format("woff2"),
    url("../../public/assets/fonts/GoldLinesTrial-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  // font-family: "GoldLines";
  // font-family: "SF Pro Display";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

html {
  scroll-behavior: smooth;
  cursor: url("../svgs/cursor-arrow.svg"), auto;
}
#root {
  display: flex;
  flex-direction: column;
  position: relative;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

.primary-background {
  background: colors.$primary-color;
}

.secondary-background {
  background: colors.$secondary-color;
}

.highlight-color {
  color: colors.$highlight-color;
}

.primary-textcolor {
  color: colors.$secondary-color;
}

.primary-color,
.secondary-textcolor {
  color: colors.$primary-color;
}

.highlight-background {
  background: colors.$highlight-color;
}

.large-glow {
  box-shadow: 0px 4px 175px 35px colors.$highlight-color;
}

.highlight-lighten {
  color: colors.$highligh-lighten-10;
}

.text-container-shape {
  height: 0;
  border-left: 0px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 28px solid colors.$highlight-color;
}

.text-container-line {
  padding-right: 125px;
  max-width: max-content;
  border-bottom: 1px solid colors.$highlight-color;
}

@media screen and (max-width: 550px) {
  .text-container-line {
    padding: 0;
    border-bottom: none;
    max-width: 100%;
  }
}

.white-background {
  background: #fff;
}

.text-white {
  color: #fff;
}

.position-fixed {
  position: fixed;
}

.uppercase {
  text-transform: uppercase;
}

.content-container {
  max-width: 950px;
}

p {
  cursor: text;
}

.cursor-text {
  cursor: text;
}

a,
.cursor-pointer {
  cursor: url("../svgs/cursor-hand.svg") 10 0, pointer;
}

.top-0 {
  top: 0;
}

.min-h-screen {
  min-height: 100vh;
}

.h-screen {
  height: 100vh;
}

.padding-side-20 {
  padding: 0px 20px;
}

.text-center {
  text-align: center;
}

.flx {
  display: flex;
}

.row {
  flex-direction: row;
}

.flx-wrap {
  flex-wrap: wrap;
}

.flx-col {
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}

.section-container {
  display: flex;
  justify-content: center;
}

.text-bold {
  font-weight: 600;
}

.hidden {
  display: none !important;
}
